.form-landings {
    background-color: #efefef;
    padding-top: 51px;
    padding-bottom: 51px;

    &__desktop {
        background: $white;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        width: 100%;
        display: grid;
        grid-template-columns: 30% 20% 1fr;
        padding: 23px 30px 40px 30px;
        row-gap: 46px;
        column-gap: 30px;

        .--text-l {
            margin-bottom: 40px;
        }

        .--input {
            grid-column: 1;
            max-width: 100%;
            width: 100% !important;

            .form__control {
                border: none !important;
                border-bottom: 1px solid #c4c4c4 !important;
                border-radius: 0% !important;
                padding-left: 0 !important;

                &::placeholder {
                    font-size: 16px !important;
                    line-height: 24px !important;
                    font-weight: 400 !important;
                }

                &:hover {
                    border-bottom: 1px solid $green !important;
                    border-radius: 0% !important;
                }
            }

            .form__label {
                font-size: 14px !important;
                line-height: 20px !important;
                font-weight: 400 !important;
                left: 0 !important;
                top: -18px !important;
                padding-left: 0 !important;
            }
        }

        .--input.hidden_options {
            grid-column: 1 / span 2;
        }

        .form-contact__checks {
            grid-row: 2 / span 3;
            grid-column: 2;
            width: 100% !important;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .form__field .--checkbox  {
                justify-content: flex-end;

            }
            .form__field {
                display: flex;
               flex-direction: column;
               gap: 10px;
            }
        }

        .--textarea {
            grid-row: 2 / span 3;
            grid-column: 3;
            width: 100% !important;

            label {
                display: flex;
                justify-content: space-between;
                color: $green;
                margin-bottom: 5px;

                p:last-child {
                    font-style: italic;
                }
            }

            textarea {
                max-width: 100%;
                width: 100% !important;
                height: 100% !important;
                background: #f6fef9 !important;
                border-radius: 10px !important;
                border: none !important;
            }
        }

        .control {
            grid-column: 3;
            grid-row: 6;
            max-width: 100%;

            .btn {
                background: #f7931d;
                border-radius: 30px;
                max-width: 100%;
                width: 100%;
                height: 50px;
                color: $white;
                &:hover {
                    background: $green;
                    transition: background 0.2s ease-out;
                    border-color: transparent;
                }
            }
        }
    }

    &__mobile {
        display: none;
        background: $white;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 26px 23px 33px 23px;
        min-height: 538px;

        &.--form-width {
            max-width: 800px;
            margin: 0 auto;
            padding: 4rem 2rem;
        }

        .--text-l {
            margin-bottom: 20px;
            text-align: center;
        }

        .form__step {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            column-gap: 1.875rem;
            row-gap: 46px;

            @include device-md('min') {
                flex-direction: row;
            }

            .form__field {
                width: auto;
                flex: 1 1 100%;

                @include device-md('min') {
                    flex: 1 1 45%;
                }
            }

            .--input {
                grid-column: 1;
                max-width: 100%;
                width: 100% !important;

                .form__control {
                    border: none !important;
                    border-bottom: 1px solid #c4c4c4 !important;
                    border-radius: 0% !important;
                    padding-left: 0 !important;

                    &::placeholder {
                        font-size: 16px !important;
                        line-height: 24px !important;
                        font-weight: 400 !important;
                    }

                    &:hover {
                        border-bottom: 1px solid $green !important;
                        border-radius: 0% !important;
                    }
                }

                .form__label {
                    font-size: 14px !important;
                    line-height: 20px !important;
                    font-weight: 400 !important;
                    left: 0 !important;
                    top: -18px !important;
                    padding-left: 0 !important;
                }
            }

            .form-contact__checks {

                display: flex;
                flex-direction: column;
                gap: 20px;
                .form__field .--checkbox  {
                    justify-content: flex-end;

                }
                .form__field {
                    display: flex;
                   flex-direction: column;
                   gap: 10px;
                }
            }

            .--textarea {
                width: 100% !important;

                label {
                    display: flex;
                    justify-content: space-between;
                    color: $green;
                    margin-bottom: 5px;

                    p:last-child {
                        font-style: italic;
                    }
                }

                textarea {
                    max-width: 100%;
                    width: 100% !important;
                    height: 73px !important;
                    background: #f6fef9 !important;
                    border-radius: 10px !important;
                    border: none !important;
                }
            }

            .control {
                max-width: 100%;
                width: 100%;

                .btn {
                    background: #f7931d;
                    border-radius: 30px;
                    max-width: 100%;
                    width: 100%;
                    height: 50px;
                    color: $white;
                    &:hover {
                        background: $green;
                        transition: background 0.2s ease-out;
                        border-color: transparent;
                    }
                }
            }
        }

        .form__step:not(.--active) {
            display: none;
        }

        .steps {
            display: flex;
            margin-bottom: 2rem;

            &__item {
                position: relative;
                color: $medium-gray;
                flex: 1 1 50%;

                display: flex;
                flex-direction: column;
                align-items: center;

                @include device-md('min') {
                    padding-right: 3.125rem;
                    flex: 0 1 auto;
                }

                &:not(:last-of-type)::after {
                    content: '';
                    position: absolute;
                    display: block;
                    top: 12px;
                    left: 25%;
                    width: 100%;
                    height: 3px;
                    background-color: currentColor;

                    @include device-md {
                        left: 50%;
                    }
                }

                &__number {
                    position: relative;
                    background-color: $white;
                    border: 2px solid $medium-gray;
                    color: $dark-gray;
                    padding: 0.4rem;
                    margin-bottom: 0.25rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    transition: all 0.3s ease-in-out;
                    z-index: 1;
                }

                &__label {
                    color: $dark-gray;
                    transition: all 0.3s ease-in-out;
                    font-weight: 400;
                }

                &.--validated {
                    color: $green;
                    transition: all 0.3s ease-in-out;

                    .steps__item__number {
                        background-color: $green;
                        border-color: $green;
                        color: $white;
                    }

                    .steps__item__label {
                        color: $green;
                        font-weight: 700;
                    }
                }

                &.--active {
                    transition: all 0.3s ease-in-out;
                    color: $medium-gray;

                    .steps__item__number {
                        background-color: $light-green-50;
                        color: $green;
                        border: 2px solid $green;
                    }

                    .steps__item__label {
                        color: $green;
                        font-weight: 700;
                    }
                }
            }
        }

        .is-grouped {
            display: flex;
            gap: 1rem;
        }

        .form__field.--textarea {
            width: 100%;
            flex: 1 1 100%;
            textarea {
                width: 100%;
            }
        }
    }

    @include device-md {
        &__desktop {
            display: none;
        }

        &__mobile {
            display: block;
        }
    }

    .form__error {
        padding-left: 0 !important;
    }
}

.form-landing-w-img {
    display: grid;
    grid-template-columns:
    1fr repeat(11, minmax(0, calc(($min_width_wrapper - (30px * 11)) / 12)) 30px)
    minmax(0, calc(($min_width_wrapper - (30px * 11)) / 12))
    1fr;
    &.wrapper {
        @include device-md("min"){
            padding: 0;
            margin: 0;
            max-width: 100%;
            width: 100%;
        }
    }
    @include device-md {
        @include main-grid();
    }

    &__image {
        grid-column: 1 / 11;
        @include device-md{
            grid-column: 1/-1;
            margin: auto -16px;
        }
        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            width: 100%
        }
    }

    &__content {
        grid-column: 12/24;
        @include device-md{
            grid-column: 1/-1;
        }
        &__title {
            letter-spacing: -0.02em;
            color: $green;
            margin-bottom: 28px;
            @include font-size(40, 53.5, 400);
            @include device-md {
                @include font-size(24, 30, 400);
                margin-bottom: 20px;
                margin-top: 30px;
            }
        }
        &__subtitle {
            @include font-size(24, 30, 400);
            margin-bottom: 34px;
            @include device-md {
                @include font-size(18, 28, 400);
            }
        }
        &__form-title {
            @include font-size(18, 28, 400);
            margin-bottom: 48px;
            @include device-md {
                @include font-size(18, 28, 400);
                margin-bottom: 34px;
            }
        }
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 44px;
        padding-bottom: 54px;

        .--input {
            max-width: 100%;
            width: 100%;

            .form__control {
                border: none !important;
                border-bottom: 1px solid #c4c4c4 !important;
                border-radius: 0% !important;
                padding-left: 0 !important;

                &::placeholder {
                    font-size: 16px !important;
                    line-height: 24px !important;
                    font-weight: 400 !important;
                }

                &:hover {
                    border-bottom: 1px solid $green !important;
                    border-radius: 0% !important;
                }
            }

            .form__label {
                font-size: 14px !important;
                line-height: 20px !important;
                font-weight: 400 !important;
                left: 0 !important;
                top: -18px !important;
                padding-left: 0 !important;
            }
        }

        .--textarea {
            width: 100% !important;

            label {
                display: flex;
                justify-content: space-between;
                color: $green;
                margin-bottom: 5px;

                p:last-child {
                    font-style: italic;
                }
            }
        }

        .form__error {
            padding-left: 0 !important;
        }

        .btn {
            background: #f7931d;
            border-radius: 30px;
            max-width: 100%;
            width: 100%;
            height: 50px;
            color: $white;
            &:hover {
                background: $green;
                transition: background 0.2s ease-out;
                border-color: transparent;
            }
        }
    }
}

.text-class {
    display: none;
}
